import Gs from '../view/Mrin/Gs'
import Mrin from '../view/Mrin/Mrin'
import Contact from '../view/Mrin/Contact'
import Login from '../view/Mrin/Login'
import Regist from '../view/Mrin/Regist'
const data = [
    {
        path:'/gs',
        component:Gs
    },
    {
        path:'/mrin',
        component:Mrin
    },
    {
        path:'/contact',
        component:Contact
    },
    {
        path:'/login',
        component:Login
    },
    {
        path:'/regist',
        component:Regist
    },
    {
        to:'/mrin',
        from:'/'
    },
]
export default data 