import React, { Component } from 'react';
import moduleName from '../../img/icon.png';
import { NavLink } from 'react-router-dom';

export default class componentName extends Component {
  render() {
    return (
      <div> 
        <div className='headers'>
            <div className='headers-left'>
             <img src={moduleName} className='headers-s' sty alt='' />
            </div>
            <ul className='headers-ul'>
                <li className='headers-li'><a className='headers-a'>关于我们</a> </li>
                <li className='headers-li'><a  className='headers-a'>联系我们</a> </li>
                <li className='headers-li'><a  className='headers-a'>登录</a> </li>
                <li className='headers-li'><a  className='headers-a'>注册</a> </li>
            </ul>
        </div>         
      </div>
    );
  }
}
