import React, { Component } from 'react';

export default class componentName extends Component {
  render() {
    return (
      <div className='Md'> 
        <div className='Mc'>
            <ul className='Mul'>
                <li className='Mli'>
                    <a className='Ma'>
                    </a>    
                </li>    
            </ul>   
         </div>     
      </div>
    );
  }
}
