import React, { Component } from 'react'
import { Carousel } from 'antd';
import banner from '../../img/banner.jpg'
import one1 from '../../img/1.jpeg'
import { Image } from 'antd';
import one2 from '../../img/2.jpeg'
import one3 from '../../img/3.jpeg'
import one4 from '../../img/4.jpeg'
import one5 from '../../img/5.jpeg'
import one6 from '../../img/6.jpeg'
import one7 from '../../img/7.jpeg'
import one8 from '../../img/8.jpeg'
import one9 from '../../img/9.jpeg'
import one10 from '../../img/10.jpeg'

const contentStyle = {
  width:'100%',
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};
export default class componentName extends Component {
  render() {
    return (
      <div className='oone'>
         <Carousel autoplay>
          <div style={{width:'100%'}}>
            <img style={{width:'100%'}} src={banner} alt='' />
          </div>
        </Carousel>
        <div className='headers11'>
          <h2>元语互动</h2>
          <p>不用做选择，每一款都是你最心仪的故事</p>
          <p>
          局外人，你当够了吗？</p>
          <p>现在，终于可以不再只是阅读别人的故事，你将参与其中，主导命运的走向。因为，故事的发展取决于你的选择。</p>
          <p>【核心体验】</p>
          <p>打破传统小说的固定阅读模式，在精致立绘、动人音乐的陪伴中，真正地沉浸式体验丰富多彩的互动剧情。你的每一次选择，都可能会导向完全不同的结果，人物命运由你来决定。
            互动阅读作品类型丰富，西幻、偶像、奇幻、都市、悬疑……随时开启沉浸式互动阅读旅程。</p>
        </div>
        <div className='headers1'>
          <div className='headers2'>
          <Image width={243} height={540} src={one1}/>
          <Image width={243} height={540} src={one2}/>
          <Image width={243} height={540} src={one3}/>
          <Image width={243} height={540} src={one4}/>
          <Image width={243} height={540} src={one5}/>
          <Image width={243} height={540} src={one6}/>
          <Image width={243} height={540} src={one7}/>
          <Image width={243} height={540} src={one8}/>
          <Image width={243} height={540} src={one9}/>
          <Image width={243} height={540} src={one10}/>
          </div>
        </div>
      </div>
    )
  }
}
