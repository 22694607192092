import React, { Component } from 'react';
import R from './Routers'
export default class componentName extends Component {
  render() {
    return (
      <div> 
        <R />
      </div>
    );
  }
}
