import React, { Component } from 'react';

export default class componentName extends Component {
    submit=()=>{
        if(this.refs.input1.value!==''){
            if(this.refs.input2.value!==''){    
                if(this.refs.input1.value === sessionStorage.getItem('user')){
                    if(this.refs.input2.value === sessionStorage.getItem('password')){
                        alert('成功')
                        window.location.href='/index.html#/mrin'
                    }else{
                        alert('密码错误,请重新输入')
                    }
                }else{
                    alert('账号错误,请重新输入')
                }
            }else{
                alert('密码不能为空')
            }
        }else{
            alert('账号不能为空')  
        }
    }
    render() {
        return (
            <div className='mrin'> 
                <div className='mrin-left'>
                    <div className='mrin-title'>
                        <h2 className='mrin-h2'>登录</h2>
                    </div>
                    <div className='content1'>
                        <div className='content2'>
                            <div className='content3'>
                                <div className='content4'><p className='contentp'>账号:</p><input className='contentin' ref='input1' /></div>
                                <div className='content4'><p className='contentp'>密码:</p><input className='contentin' ref='input2'/></div>
                                <div className='content4'><p className='contentp'></p><button className='contentp1' onClick={()=>{this.submit()}}>登录</button><div>没有账号?点击<a href='/index.html#/regist'>注册</a></div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    }
