import React, { Component } from 'react';

export default class componentName extends Component {
  render() {
    return (
      <div className='mrin'>
        <div className='mrin-left'>
            <div className='mrin-title'>
                <h2 className='mrin-h2'>公司简介</h2>
            </div>
            <div className='mrin-body'>
                <div className='mrin-body1'>
                  <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;冰狮互动科技有限公司是成为一家优质的内容产出及发行公司。冰狮互动汇集了众多高质量作品，并持续整合更丰富、更优质的文化资源，为广大艺术文化爱好
                  
                  者提供最专业的服务。 公司致力于帮助更多的艺术创作者实现自我价值，为他们提供更大的舞台。“IceSimba”寓意我们将致力于创意(Innovation)、文化(Culture)、娱乐(Entertainment)等领域，成为影响及引导人类未来文化和生活方式的领先企业。
               
                  养心益智的目的，又能构建一个向全社会书画爱好者提供终身学习服务和书画贸易交流的最佳平台。
                  </p>
                </div>
            </div>
          </div>
      
       </div>
    );
  }
}
