
import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

function RouterView(props) {
    const routers = props.config.filter((item) => {
        return !item.from
    })

    const redirect = props.config.filter((item) => {
        return item.from
    })

    return (
        <Switch>
            {
                routers.map((item, index) => {
                    return (
                        <Route key={index} path={item.path} render={(routerProps) => {
                            return (
                                <item.component {...routerProps} data={item.chlidren} key={item.path}></item.component>
                            )
                        }}>
                        </Route>
                    )
                })
            }
            {
                redirect.map((item, index) => {
                    return (
                        <Redirect key={index} from={item.from} to={item.to}></Redirect>
                    )
                })
            }
        </Switch>
    )
}
export default RouterView;
