import React, { Component } from 'react';

export default class componentName extends Component {
  render() {
    return (
        <div className='mrin'> 
            <div className='mrin-left'>
                <div className='mrin-title'>
                    <h2 className='mrin-h2'>联系我们</h2>
                </div>
                <div className='content1'>
                    <div className='content2'>
                        <div className='content3'>
                                联  系  人： 杨红  
                                <br />  
                                联系电话： 18911238431
                                <br />  
                                办公地址： 北京市朝阳区东外五十六号文创园A座创新港302-03 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}
