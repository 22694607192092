import React, { Component } from 'react';

export default class componentName extends Component {
  render() {
    return (
      <div className='footer'> 
        <div className='footer-li'>
            <span className='footer-span'>
            Copyright © 2020 北京冰狮科技有限公司,All Rights Reserved <a href='https://beian.miit.gov.cn'>京ICP备 15052514号</a> 公司地址：北京市朝阳区东外五十六号3-302 
            </span>
        </div>
       </div>
    );
  }
}
