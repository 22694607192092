import React, { Component } from 'react';
import Header from './view/Header/Header'
import Footer from './view/Footer/Footer'
import Main from './view/Main/Main'
import RouteView from './Route/RouteView'
import config from './Route/Routes'
import './App.css'
export default class componentName extends Component {
  render() {
    return (
      <div className='app'> 
        <Header />
        <Main />
        <RouteView config={config} />
        <Footer />
       </div>
    );
  }
}
