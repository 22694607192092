import React, { Component } from 'react';

export default class componentName extends Component {
    submit=()=>{
        if(this.refs.input1.value!==''){
            if(this.refs.input2.value!==''){
                if(this.refs.input3.value!==''){
                    sessionStorage.setItem('user',this.refs.input1.value)
                    sessionStorage.setItem('password',this.refs.input2.value)
                    sessionStorage.setItem('name',this.refs.input3.value)
                    alert('注册成功')
                    window.location.href='/index.html#/login'
                }else{
                    alert('昵称不能为空')
                }
            }else{
                alert('密码不能为空')
            }
        }else{
            alert('账号不能为空')
        }
       
    }
    render() {
        return (
            <div className='mrin'> 
                <div className='mrin-left'>
                    <div className='mrin-title'>
                        <h2 className='mrin-h2'>注册</h2>
                    </div>
                    <div className='content1'>
                        <div className='content2'>
                            <div className='content3'>
                                <div className='content4'><p className='contentp'>账号:</p><input className='contentin' ref='input1' /></div>
                                <div className='content4'><p className='contentp'>密码:</p><input className='contentin' ref='input2'/></div>
                                <div className='content4'><p className='contentp'>昵称:</p><input className='contentin' ref='input3'/></div>
                                <div className='content4'><p className='contentp'></p><button className='contentp1' onClick={()=>{this.submit()}}>注册</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    }
